<!--  -->
<template>
<div class="filePage">
    <div class="protocol-head">
        <div class="protocol-head-container flex-vc flex">
            <a href="/" class="flex-vc flex">
                <div class="logo">
                    <img src="../../assets/img/default/upupoo.png">
                </div>
                <span class="text">upupoo</span>
            </a>
        </div>
    </div>
    <div class="protocol-layout-body">
        <h3>《版权声明》</h3>
        <p class="protocal-title-bold">用户在接受UPUPOO服务之前，请务必仔细阅读本条款并同意本声明。</p>
        <p class="protocal-title-bold">用户直接或通过各类方式间接使用UPUPOO服务和数据的行为，均将被视作已接受本声明所涉全部内容；若用户对以下声明的任一条款持有异议，请终止使用UPUPOO所提供的全部服务。</p>
        <p>1、用户发表在UPUPOO的原创图片等内容的版权均归用户本人所有。UPUPOO仅提供平台和空间供注册用户上传、浏览和下载内容，内容系用户自行上传。</p>
        <p>2、用户从UPUPOO或其他网站通过转载、复制、截图等方式获取他人内容，并发布在UPUPOO的，相关内容的版权属于原作者。用户不得侵犯他人包括版权在内的知识产权及其他权利，因用户发布侵权作品引发知识产权或其他法律纠纷的，用户须自行承担一切后果，与UPUPOO无关。</p>
        <p>3、UPUPOO有权对用户发布的内容进行审核，并有权根据相关证据结合《中华人民共和国民法典》《信息网络传播权保护条例》等法律法规对侵权信息进行处理。</p>
        <p>4、UPUPOO尊重权利人的知识产权和合法权益。若权利人认为UPUPOO用户上传的内容侵犯自身版权或其他合法权益，可依法向UPUPOO投诉邮箱【 】发出书面申请，申请应当包括构成侵权的初步证据及权利人的真实身份信息。UPUPOO在审核相关材料后，有权自行决定对相关内容采取删除、屏蔽或断开链接等必要措施，并依法保留相关数据。在UPUPOO发布内容，即视为用户同意UPUPOO就前述情况所采取的相应措施，UPUPOO不因此而承担任何违约、侵权或其他法律责任。</p>
        <p>5、UPUPOO根据上述第4条原因删除相关内容的，相应发布用户如认为投诉不属实，可依法向UPUPOO投诉邮箱【upupoo@upupoo.com】发出关于被采取必要措施内容不侵权的书面声明，声明应当包括不存在侵权行为的初步证据及网络用户的真实身份信息。声明发出后，UPUPOO在书面审核相关材料后，根据实际情况依法进行处理。</p>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.filePage {
  background: #fff;
  width: 100%;
  height: 100%;

  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }

  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    min-height: 886px;
    color: #000;
    font-size: 16px;
  }

  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }

  .protocol-layout-body p {
    margin-bottom: 20px;
    line-height: 24px;
  }

  .protocal-title-bold {
    font-weight: bold;
  }
}
</style>
